import React from "react";
import theme from "theme";
import { Theme, Link, Image, Text, Box, Input, Icon, Hr, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { MdSearch } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"dashboard"} />
		<Helmet>
			<title>
				Visionerves
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Visionerves — What if we could see the nerves"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<meta name={"msapplication-TileColor"} content={"#2a607e"} />
		</Helmet>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-secondary url(https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Calque_12.svg?v=2024-02-23T19:22:55.708Z) center/cover repeat-x scroll">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				width="100%"
			>
				<Box
					min-width="100px"
					min-height="863px"
					width="20%"
					background="--color-primary"
					border-radius="6px"
				>
					<Box min-width="100px" min-height="100px" display="flex">
						<Image src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/LOGO%20VISIONERVES_1%202.svg?v=2024-02-22T22:31:13.942Z" display="block" width="30%" padding="0px 0px 0px 15px" />
						<Text
							margin="0px 0px 0px 0px"
							align-self="center"
							font="--lead"
							color="--secondary"
							width="70%"
							padding="0px 0px 0px 10px"
						>
							VISIONERVES
							<br />
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px" width="80%">
					<Text margin="0px 0px 0px 0px" font="--headline4" color="--primary">
						TABLEAU DE BORD
					</Text>
					<Text margin="10px 0px 0px 0px" font="--textBase" color="--primary">
						Recherchez votre patient par IPP
					</Text>
					<Box min-width="100px" min-height="50px" display="flex">
						<Input
							display="block"
							placeholder-color="LightGray"
							background="white"
							align-self="center"
							border-radius="6px"
							margin="2px 2px 2px 0px"
						/>
						<Icon
							category="md"
							icon={MdSearch}
							size="64px"
							align-self="center"
							color="--primary"
							width="32px"
							height="32px"
							margin="0px 0px 0px 6px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="50px"
						display="block"
						height="500px"
						margin="8px 0px 0px 0px"
						border-radius="6px"
						border-color="#C0D8E5"
						border-width="2px"
						border-style="solid"
						background="--color-secondary"
					>
						<Text margin="0px 0px 0px 0px">
							Some text
						</Text>
						<Box min-width="100px" min-height="100px" display="flex">
							<Box min-width="100px" min-height="100px" width="25%">
								<Text margin="0px 0px 0px 0px">
									Some text
								</Text>
							</Box>
							<Box min-width="100px" min-height="100px" width="25%">
								<Text margin="0px 0px 0px 0px">
									Some text
								</Text>
							</Box>
							<Box min-width="100px" min-height="100px" width="25%">
								<Text margin="0px 0px 0px 0px">
									Some text
								</Text>
							</Box>
							<Box min-width="100px" min-height="100px" width="25%">
								<Text margin="0px 0px 0px 0px">
									Some text
								</Text>
							</Box>
						</Box>
						<Hr min-height="10px" min-width="98%" padding="0px 0px 4px 0px" width="98%" />
					</Box>
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});