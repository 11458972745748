import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Text, Button, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Visionerves
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Visionerves — What if we could see the nerves"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z"} />
			<meta name={"msapplication-TileColor"} content={"#2a607e"} />
		</Helmet>
		<Section padding="36px 0 36px 0" quarkly-title="Header" background="#2A607E" height="84px">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="5%"
				sm-width="50%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
			>
				<Image height="auto" src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/LOGO%20VISIONERVES_1%202.svg?v=2024-02-22T22:31:13.942Z" width="50px" max-width="171px" />
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="5%"
				sm-width="50%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
			>
				<Text margin="0px 0px 0px 0px" color="--secondary" padding="0px 0px 0px 5px" font="--lead">
					VISIONERVES
				</Text>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="90%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="none"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							href="#"
							font="--base"
							text-decoration-line="initial"
							color="--secondary"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
						>
							Accueil
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#about"
							font="--base"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 20px 0px 20px"
							opacity="0.8"
						>
							Cas d'usage
						</Link>
						<Link
							color="--grey"
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="#contact"
							font="--base"
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
						>
							Foncionalités
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#testimonial"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							Équipe
						</Link>
						<Button
							background="--color-secondary"
							border-radius="6px"
							color="--primary"
							border-color="--color-secondary"
							margin="2px 2px 2px 24px"
						>
							Contact
						</Button>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="--color-darkL2 url(https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Rectangle%201.svg?v=2024-03-15T13:03:48.102Z)" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0" padding="100px 0 100px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				background="rgba(0, 0, 0, 0)"
				display="block"
			/>
			<Box
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					VISIONERVES RÉVOLUTIONNE L'IMAGERIE MÉDICALE EN FAISANT APPARAÎTRE LES NERFS
				</Text>
				<Text
					margin="30px 0px 0px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="60%"
					sm-width="100%"
				>
					VISIONERVES transforme en quelques clics, grâce à l'intelligence artificielle, des images IRM en modèles 3D de l’anatomie du patient incluant la représentation du système nerveux
				</Text>
			</Box>
		</Section>
		<Section
			padding="70px 0 100px 0"
			background="#ECF3F6 url(https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Vector.svg?v=2024-03-25T19:57:18.044Z) top left no-repeat scroll padding-box"
			sm-padding="0px 0 0px 0"
			md-padding="50px 0 50px 0"
			display="none"
		>
			<Override
				slot="SectionContent"
				display="flex"
				grid-gap="16px"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-gap="0px"
				md-grid-template-columns="repeat(2, 1fr)"
			/>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
				align-self="auto"
			>
				<Text margin="0px 0px 20px 0px" font="--headline4" color="--primary" align-self="center">
					CAS D'USAGE
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--textBase"
					color="--primary"
					align-self="center"
					text-align="center"
					width="50%"
				>
					VISIONERVES offre une modélisation 3D automatique et rapide (&lt;1H) intégrant les nerfs des images IRM du patient pour :
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				justify-content="space-around"
				position="relative"
				flex-wrap="wrap"
				display="flex"
			>
				<Box
					border-radius="6px"
					flex-direction="column"
					width="23%"
					md-padding="0px 0px 0px 0px"
					sm-padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					margin="16px 10px 16px 10px"
					lg-margin="0px 0px 0px 0px"
					md-width="100%"
					sm-width="100%"
					padding="16px 16px 16px 16px"
					lg-width="50%"
					background="--color-light"
					box-shadow="4px 0 11px 0 rgba(0, 0, 0, 0.05)"
				>
					<Image
						src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22:19:46.861Z"
						border-radius="6px"
						margin="0px 7px 0px 7px"
						md-width="inherit"
						max-width="100%"
						align-self="stretch"
						srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22%3A19%3A46.861Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22%3A19%3A46.861Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22%3A19%3A46.861Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22%3A19%3A46.861Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22%3A19%3A46.861Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22%3A19%3A46.861Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/surgeon-writing-clipboard-operation-room-anaesthesiologist-writing-updates.jpg?v=2024-03-25T22%3A19%3A46.861Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						color="--primary"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						margin="20px 0px 0px 0px"
						font="--lead"
						text-align="center"
					>
						OPTIMISER LA STRATEGIE CHIRURGICALE
					</Text>
				</Box>
				<Box
					border-radius="6px"
					flex-direction="column"
					width="23%"
					md-padding="0px 0px 0px 0px"
					sm-padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					margin="16px 10px 16px 10px"
					lg-margin="0px 0px 0px 0px"
					md-width="100%"
					sm-width="100%"
					padding="16px 16px 16px 16px"
					lg-width="50%"
					background="--color-light"
					box-shadow="4px 0 11px 0 rgba(0, 0, 0, 0.05)"
				>
					<Image
						position="static"
						padding="0px 0px 0px 0px"
						border-radius="6px"
						margin="0px 7px 0px 7px"
						src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22:23:39.665Z"
						md-width="inherit"
						max-width="100%"
						order="0"
						align-self="stretch"
						srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22%3A23%3A39.665Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22%3A23%3A39.665Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22%3A23%3A39.665Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22%3A23%3A39.665Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22%3A23%3A39.665Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22%3A23%3A39.665Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medium-shot-patient-doctor.jpg?v=2024-03-25T22%3A23%3A39.665Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						color="--primary"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						margin="20px 0px 0px 0px"
						font="--lead"
						align-self="center"
						text-align="center"
					>
						OPTIMISER LE DÉROULEMENT DE LA CHIRURGIE
					</Text>
				</Box>
				<Box
					border-radius="6px"
					flex-direction="column"
					width="23%"
					md-padding="0px 0px 0px 0px"
					sm-padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					margin="16px 10px 16px 10px"
					lg-margin="0px 0px 0px 0px"
					md-width="100%"
					sm-width="100%"
					padding="16px 16px 16px 16px"
					lg-width="50%"
					background="--color-light"
					box-shadow="4px 0 11px 0 rgba(0, 0, 0, 0.05)"
				>
					<Image
						src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22:26:37.974Z"
						overflow-y="visible"
						position="static"
						align-self="stretch"
						padding="0px 0px 0px 0px"
						border-radius="6px"
						margin="0px 7px 0px 7px"
						md-width="inherit"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22%3A26%3A37.974Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22%3A26%3A37.974Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22%3A26%3A37.974Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22%3A26%3A37.974Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22%3A26%3A37.974Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22%3A26%3A37.974Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/Capture%20d%E2%80%99%C3%A9cran%202024-03-25%20%C3%A0%2023.26.16.png?v=2024-03-25T22%3A26%3A37.974Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						color="--primary"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						margin="20px 0px 0px 0px"
						font="--lead"
						align-self="center"
						text-align="center"
					>
						ACCELERER LA COURBE D'APPRENTIDSAGE
					</Text>
				</Box>
				<Box
					border-radius="6px"
					flex-direction="column"
					width="23%"
					md-padding="0px 0px 0px 0px"
					sm-padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					margin="16px 10px 16px 10px"
					lg-margin="0px 0px 0px 0px"
					md-width="100%"
					sm-width="100%"
					padding="16px 16px 16px 16px"
					lg-width="50%"
					background="--color-light"
					box-shadow="4px 0 11px 0 rgba(0, 0, 0, 0.05)"
				>
					<Image
						src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22:09:37.587Z"
						position="static"
						padding="0px 0px 0px 0px"
						border-radius="6px"
						margin="0px 7px 0px 7px"
						object-fit="contain"
						md-width="inherit"
						max-width="100%"
						align-self="stretch"
						srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22%3A09%3A37.587Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22%3A09%3A37.587Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22%3A09%3A37.587Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22%3A09%3A37.587Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22%3A09%3A37.587Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22%3A09%3A37.587Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/medecin-dans-son-cabinet-regardant-cerveau-essayant-poser-diagnostic-medecin-donnant-son-expertise.jpg?v=2024-03-25T22%3A09%3A37.587Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						color="--primary"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						margin="20px 0px 0px 0px"
						font="--lead"
						align-self="center"
						text-align="center"
					>
						OPTIMISER l'ALLIANCE THERAPEUTIQUE
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.QuarklycommunityKitYouTube
			url="https://youtu.be/RAAbioRgnns"
			autoplay
			controls={false}
			disablekb={false}
			loop
			fs={false}
			modestbranding
			showOverlay={false}
			display="none"
		/>
		<Section
			md-padding="60px 0 60px 0"
			padding="70px 0 60px 0"
			sm-padding="60px 0 60px 0"
			background="--color-grey url(https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Vector.svg?v=2024-03-15T17:00:51.774Z) 0% 90%/auto no-repeat scroll"
			z-index="1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="flex-start"
				justify-content="space-around"
				flex-wrap="wrap"
			/>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
				align-self="auto"
			>
				<Text margin="0px 0px 20px 0px" font="--headline4" color="--primary" align-self="center">
					FONCTIONNALITÉS
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--textBase"
					color="--primary"
					align-self="center"
					text-align="center"
					width="80%"
				>
					La solution VISIONERVES permet aux radiologues et chirurgiens de modéliser et visualiser en 3D, rapidement et en toute autonomie, l’anatomie de leurs patients en intégrant les nerfs périphériques. VISIONERVES est une solution SAAS :
				</Text>
			</Box>
			<Image
				src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Vector-1.svg?v=2024-03-15T17:08:50.955Z"
				bottom="auto"
				left="auto"
				right="0px"
				position="absolute"
				top="auto"
				opacity="1"
				align-self="flex-end"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				justify-content="space-around"
				position="relative"
				flex-wrap="wrap"
				display="flex"
			>
				<Box
					border-radius="6px"
					flex-direction="column"
					width="31%"
					md-padding="0px 0px 0px 0px"
					sm-padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					margin="16px 10px 16px 10px"
					lg-margin="0px 0px 0px 0px"
					md-width="100%"
					sm-width="100%"
					padding="16px 16px 16px 16px"
					lg-width="50%"
					background="--color-light"
					box-shadow="4px 0 11px 0 rgba(0, 0, 0, 0.05)"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Group%201.svg?v=2024-03-15T18:24:47.104Z"
						display="block"
						width="62px"
						height="62px"
						align-self="center"
						margin="20px 0px 0px 0px"
					/>
					<Text
						color="--primary"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						margin="20px 0px 0px 0px"
						font="--lead"
						align-self="center"
					>
						AUTOMATIQUE
					</Text>
					<Text
						margin="20px 0px 20px 0px"
						color="--text"
						font="--base"
						opacity=".9"
						text-align="center"
					>
						Les modèles 3D sont générés automatiquement à partir d'images d'IRM grâce à nos méthodes d'IA brevetées. Vous pouvez donc générer en toute autonomie vos modèles sans passer par un expert extérieur à votre service.
					</Text>
				</Box>
				<Box
					border-radius="6px"
					flex-direction="column"
					width="31%"
					md-padding="0px 0px 0px 0px"
					sm-padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					margin="16px 10px 16px 10px"
					lg-margin="0px 0px 0px 0px"
					md-width="100%"
					sm-width="100%"
					padding="16px 16px 16px 16px"
					lg-width="50%"
					background="--color-light"
					box-shadow="4px 0 11px 0 rgba(0, 0, 0, 0.05)"
					sm-margin="0px 0px 40px 0px"
					sm-min-height="361px"
				>
					<Image
						src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Group%202.svg?v=2024-03-15T18:46:39.190Z"
						display="block"
						width="62px"
						height="62px"
						align-self="center"
						margin="20px 0px 0px 0px"
					/>
					<Text
						color="--primary"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						margin="20px 0px 0px 0px"
						font="--lead"
						align-self="center"
					>
						RAPIDE
					</Text>
					<Text
						margin="20px 0px 20px 0px"
						color="--text"
						font="--base"
						opacity=".9"
						text-align="center"
					>
						En moins de 2 heures vos modèles 3D sont prêts, ce qui facilité leur usage en routine.
					</Text>
				</Box>
				<Box
					border-radius="6px"
					flex-direction="column"
					width="31%"
					md-padding="0px 0px 0px 0px"
					sm-padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					margin="16px 10px 16px 10px"
					lg-margin="0px 0px 0px 0px"
					md-width="100%"
					sm-width="100%"
					padding="16px 16px 16px 16px"
					lg-width="50%"
					background="--color-light"
					box-shadow="4px 0 11px 0 rgba(0, 0, 0, 0.05)"
				>
					<Image
						src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/Group%203.svg?v=2024-03-15T18:46:53.394Z"
						display="block"
						width="62px"
						height="62px"
						align-self="center"
						margin="20px 0px 0px 0px"
					/>
					<Text
						color="--primary"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						margin="20px 0px 0px 0px"
						font="--lead"
						align-self="center"
					>
						INTUITIF
					</Text>
					<Text
						margin="20px 0px 20px 0px"
						color="--text"
						font="--base"
						opacity=".9"
						text-align="center"
					>
						L'interface, accessible depuis un simple navigateur web, vous permet d'apporter des modifications sur les modèles 3D au besoin, faire des annotations et échanger en temps réel avec le radiologue qui a réalisé l'examen.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="70px 0 100px 0" md-padding="60px 0 60px 0" background="--color-light" display="none">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
				align-self="auto"
			>
				<Text margin="0px 0px 20px 0px" font="--headline4" color="--primary" align-self="center">
					L'ÉQUIPE
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--textBase"
					color="--primary"
					align-self="center"
					text-align="center"
					width="50%"
				>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33.333%"
				max-height="285px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					margin="0px 0px 8px 0px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20:12:06.233Z"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20%3A12%3A06.233Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20%3A12%3A06.233Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20%3A12%3A06.233Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20%3A12%3A06.233Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20%3A12%3A06.233Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20%3A12%3A06.233Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.jpg?v=2024-03-25T20%3A12%3A06.233Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Alix Pradère
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33.333%"
				max-height="285px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					margin="0px 0px 8px 0px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16:46:48.921Z"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16%3A46%3A48.921Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16%3A46%3A48.921Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16%3A46%3A48.921Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16%3A46%3A48.921Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16%3A46%3A48.921Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16%3A46%3A48.921Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/1.png?v=2024-03-26T16%3A46%3A48.921Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Juan Pablo de la Plata
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33.333%"
				max-height="285px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					margin="0px 0px 8px 0px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16:46:48.903Z"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16%3A46%3A48.903Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16%3A46%3A48.903Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16%3A46%3A48.903Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16%3A46%3A48.903Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16%3A46%3A48.903Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16%3A46%3A48.903Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/2.png?v=2024-03-26T16%3A46%3A48.903Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Giammarco La Barbera
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33%"
				max-height="285px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20:47:32.839Z"
					margin="0px 0px 8px 0px"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20%3A47%3A32.839Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20%3A47%3A32.839Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20%3A47%3A32.839Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20%3A47%3A32.839Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20%3A47%3A32.839Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20%3A47%3A32.839Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/14.jpg?v=2024-05-29T20%3A47%3A32.839Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Enzo Bonnot
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33%"
				max-height="285px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					margin="0px 0px 8px 0px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21:00:44.808Z"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21%3A00%3A44.808Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21%3A00%3A44.808Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21%3A00%3A44.808Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21%3A00%3A44.808Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21%3A00%3A44.808Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21%3A00%3A44.808Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/17.jpg?v=2024-05-29T21%3A00%3A44.808Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Thomas Isla
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
				align-self="auto"
				margin="50px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="--headline4" color="--primary" align-self="center">
					CONSEIL MÉDICAL & SCIENTIFIQUE{" "}
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--textBase"
					color="--primary"
					align-self="center"
					text-align="center"
					width="50%"
				>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33%"
				max-height="280px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					margin="0px 0px 8px 0px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16:46:48.897Z"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16%3A46%3A48.897Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16%3A46%3A48.897Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16%3A46%3A48.897Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16%3A46%3A48.897Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16%3A46%3A48.897Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16%3A46%3A48.897Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/3.png?v=2024-03-26T16%3A46%3A48.897Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Sabine Sarnacki
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33%"
				max-height="285px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					margin="0px 0px 8px 0px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16:46:48.902Z"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16%3A46%3A48.902Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16%3A46%3A48.902Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16%3A46%3A48.902Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16%3A46%3A48.902Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16%3A46%3A48.902Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16%3A46%3A48.902Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/4.png?v=2024-03-26T16%3A46%3A48.902Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Isabelle Bloch
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				md-padding="32px 16px 16px 16px"
				width="33%"
				max-height="285px"
				display="flex"
				align-items="center"
				sm-justify-content="flex-start"
				lg-justify-content="flex-start"
				md-align-items="center"
				border-color="rgba(66, 82, 207, 0.1)"
				height="100%"
				md-width="50%"
				sm-margin="0px 0px 30px 0px"
				border-style="solid"
				lg-padding="32px 16px 16px 16px"
				lg-min-height="298px"
				md-justify-content="flex-start"
				sm-padding="32px 16px 16px 16px"
				margin="0px 0px 0px 0px"
				padding="16px 32px 16px 16px"
				border-width="0px"
			>
				<Image
					object-fit="cover"
					border-radius="50%"
					object-position="0% 0%"
					width="100px"
					height="100px"
					margin="0px 0px 8px 0px"
					src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20:46:48.727Z"
					srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20%3A46%3A48.727Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20%3A46%3A48.727Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20%3A46%3A48.727Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20%3A46%3A48.727Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20%3A46%3A48.727Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20%3A46%3A48.727Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/13.png?v=2024-05-29T20%3A46%3A48.727Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 8px 0px" font="--lead" sm-text-align="center" color="--text">
					Pietro Gori
				</Text>
				<Hr
					min-width="15%"
					margin="0 0 16px 0"
					border-width="3px 0px 0px 0px"
					border-color="--color-primary"
					padding="0px 0px 0px 0px"
				/>
				<Text
					margin="0px 0px 0px 0px"
					lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif"
					text-align="center"
					font="--textBase"
					color="--primary"
					padding="0px 15% 0px 15%"
				>
					I like the app! The doctors are very helpful and friendly. I've saved a lot on the trip.
				</Text>
			</Box>
		</Section>
		<Section
			padding="70px 0 100px 0"
			position="relative"
			lg-padding="60px 0 60px 0"
			sm-padding="30px 0 30px 0"
			md-padding="30px 0 30px 0"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				lg-padding="0px 0px 0px 0px"
				max-width="1194px"
				flex-wrap="wrap"
				flex-direction="row"
			/>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
				align-self="auto"
				margin="0px 0px 10px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline4"
					color="--primary"
					align-self="center"
					text-align="center"
				>
					IL NOUS FONT CONFIANCE
				</Text>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				sm-flex-wrap="wrap"
				sm-margin="0px 0px 0px 0px"
				justify-content="center"
				flex-wrap="wrap"
				width="100%"
				display="flex"
			>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					margin="0px 10 0px 0px"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						padding="10px 10px 10px 10px"
						background="#FFFFFF"
						border-radius="6px"
						box-shadow="--l"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16:48:18.437Z"
							sm-min-height="35px"
							border-radius="6px"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16%3A48%3A18.437Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16%3A48%3A18.437Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16%3A48%3A18.437Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16%3A48%3A18.437Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16%3A48%3A18.437Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16%3A48%3A18.437Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/5.png?v=2024-03-26T16%3A48%3A18.437Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						border-radius="6px"
						box-shadow="--l"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16:48:18.430Z"
							sm-min-height="35px"
							border-radius="6px"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16%3A48%3A18.430Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16%3A48%3A18.430Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16%3A48%3A18.430Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16%3A48%3A18.430Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16%3A48%3A18.430Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16%3A48%3A18.430Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/6.png?v=2024-03-26T16%3A48%3A18.430Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						border-radius="6px"
						box-shadow="--l"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20:53:08.057Z"
							sm-min-height="35px"
							border-radius="6px"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20%3A53%3A08.057Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20%3A53%3A08.057Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20%3A53%3A08.057Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20%3A53%3A08.057Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20%3A53%3A08.057Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20%3A53%3A08.057Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/15.png?v=2024-05-29T20%3A53%3A08.057Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						border-radius="6px"
						box-shadow="--l"
						padding="2px 10px 2px 10px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20:58:38.818Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20%3A58%3A38.818Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20%3A58%3A38.818Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20%3A58%3A38.818Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20%3A58%3A38.818Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20%3A58%3A38.818Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20%3A58%3A38.818Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/16.jpg?v=2024-05-29T20%3A58%3A38.818Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						padding="10 0px 0px 0px"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16:35:47.993Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16%3A35%3A47.993Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16%3A35%3A47.993Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16%3A35%3A47.993Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16%3A35%3A47.993Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16%3A35%3A47.993Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16%3A35%3A47.993Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/telecom_paristech.jpg?v=2024-06-17T16%3A35%3A47.993Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						padding="10 0px 0px 0px"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21:57:20.344Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21%3A57%3A20.344Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21%3A57%3A20.344Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21%3A57%3A20.344Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21%3A57%3A20.344Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21%3A57%3A20.344Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21%3A57%3A20.344Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/7.png?v=2024-03-25T21%3A57%3A20.344Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21:39:19.399Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21%3A39%3A19.399Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21%3A39%3A19.399Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21%3A39%3A19.399Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21%3A39%3A19.399Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21%3A39%3A19.399Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21%3A39%3A19.399Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/8.png?v=2024-03-25T21%3A39%3A19.399Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21:38:08.528Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21%3A38%3A08.528Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21%3A38%3A08.528Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21%3A38%3A08.528Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21%3A38%3A08.528Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21%3A38%3A08.528Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21%3A38%3A08.528Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/9.png?v=2024-03-25T21%3A38%3A08.528Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						padding="22px 10px 22px 10px"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21:40:09.823Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21%3A40%3A09.823Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21%3A40%3A09.823Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21%3A40%3A09.823Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21%3A40%3A09.823Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21%3A40%3A09.823Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21%3A40%3A09.823Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/12.png?v=2024-03-25T21%3A40%3A09.823Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21:54:37.809Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21%3A54%3A37.809Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21%3A54%3A37.809Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21%3A54%3A37.809Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21%3A54%3A37.809Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21%3A54%3A37.809Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21%3A54%3A37.809Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/11.jpg?v=2024-03-25T21%3A54%3A37.809Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						padding="18px 0px 18px 0px"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20:51:14.313Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20%3A51%3A14.313Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20%3A51%3A14.313Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20%3A51%3A14.313Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20%3A51%3A14.313Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20%3A51%3A14.313Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20%3A51%3A14.313Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/10.png?v=2024-03-25T20%3A51%3A14.313Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					border-style="solid"
					sm-padding="0px 0px 0px 0px"
					border-color="rgba(66, 82, 207, 0.1)"
					lg-padding="16px 16px 16px 16px"
					md-width="50%"
					sm-display="flex"
					sm-align-items="center"
					width="25%"
					padding="20px 10px 20px 10px"
					sm-justify-content="center"
					border-width="0px"
					align-self="center"
					height="130px"
				>
					<Box
						justify-content="center"
						sm-width="90%"
						width="100%"
						display="flex"
						align-items="center"
						lg-padding="18px 18px 18px 18px"
						background="#FFFFFF"
						box-shadow="--l"
						border-radius="6px"
						height="90px"
					>
						<Image
							src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21:58:55.239Z"
							sm-min-height="35px"
							border-radius="6px"
							background="#FFFFFF"
							srcSet="https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21%3A58%3A55.239Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21%3A58%3A55.239Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21%3A58%3A55.239Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21%3A58%3A55.239Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21%3A58%3A55.239Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21%3A58%3A55.239Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65d7b5650072400020ea6e6e/images/images-7.png?v=2024-03-25T21%3A58%3A55.239Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="70px 0 100px 0" md-padding="60px 0 60px 0" background="--color-grey" display="none">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
				align-self="auto"
			>
				<Text margin="0px 0px 20px 0px" font="--headline4" color="--primary" align-self="center">
					PRENEZ PART À LA RÉVOLUTION DE L'IMAGERIE MÉDICALE
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--primary"
					align-self="center"
					text-align="center"
					width="70%"
				>
					Cliniciens, radiologues, établissements de santé, industriels nous pouvons ensemble façonner l’imagerie médicale de demain.{" "}
					<br />
					<br />
					Ecrivez-nous si vous souhaitez en savoir plus ou tester en exclusivité VISIONERVES.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 36px 0" quarkly-title="Footer" background="#2A607E" height="84px">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="5%"
				sm-width="45%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
			>
				<Image height="auto" src="https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/LOGO%20VISIONERVES_1%202.svg?v=2024-02-22T22:31:13.942Z" width="50px" max-width="171px" />
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				sm-width="55%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
			>
				<Text margin="0px 0px 0px 0px" color="--secondary" padding="0px 0px 0px 20px" font="--base">
					Visionerves  2024. Tous droits réservés.
				</Text>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="90%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="none"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							color="--secondary"
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="#contact"
							font="--base"
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
						>
							Conditions d’utilisation
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--secondary"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#testimonial"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							Politique de confidentialité
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});