export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"65d7b5650072400020ea6e82",
				"65d7b5650072400020ea6e86",
				"65d7cc0f926d910020ed2358"
			]
		},
		"65d7b5650072400020ea6e82": {
			"id": "65d7b5650072400020ea6e82",
			"name": "404",
			"pageUrl": "404"
		},
		"65d7b5650072400020ea6e86": {
			"id": "65d7b5650072400020ea6e86",
			"name": "index",
			"pageUrl": "index"
		},
		"65d7cc0f926d910020ed2358": {
			"id": "65d7cc0f926d910020ed2358",
			"pageUrl": "dashboard",
			"name": "Dashboard"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"font": "1em ",
			"color": "--primary"
		},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z",
			"favicon_32px": "https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z",
			"background_win10": "#2a607e",
			"og:title": "Visionerves — What if we could see the nerves",
			"og:description": "",
			"og:image": "https://uploads.quarkly.io/65d7b5650072400020ea6e6e/images/fav_visionerves.png?v=2024-06-20T11:42:04.668Z",
			"title": "Visionerves",
			"description": ""
		}
	}
}